<template>
  <v-container fluid>
    <certificate-config></certificate-config>
  </v-container>
</template>

<script>
  export default {
    name: 'CertificateConfigPage',
    components: {
      CertificateConfig: () => import ('@/components/certificate_config/CertificateConfig.vue')
    },
  }
</script>

<style lang="scss" scoped>

</style>